<!--
 * @Date: 2023-03-21 19:54:59
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-03-22 16:11:18
 * @Description: 
-->
<template>
  <div class="png">
    <div class="bubble-box">
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      timer: null
    };
  },
  created() {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.png {
  width: 320px;
  height: 370px;
  background: url(./bg.png) no-repeat 0px 0px;
  position: relative;
}

.bubble {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  background: #3ee9a1;
  filter: blur(2px);
}
.bubble-box {
  position: absolute;
  top: 40px;
  left: 104px;
  width: 112px;
  height: 200px;
}
@keyframes flutter {
  0% {
    transform: translateX(0);
    bottom: 0;
    opacity: 1;
  }
  50% {
    transform: translateX(60px);
    opacity: 0.5;
  }

  100% {
    transform: translateX(0px);
    bottom: 100%;
    opacity: 0;
  }
}
@keyframes flutter2 {
  0% {
    transform: translateX(0);
    bottom: 0;
    opacity: 1;
  }
  50% {
    transform: translateX(-60px);
    opacity: 0.5;
  }

  100% {
    transform: translateX(0px);
    bottom: 100%;
    opacity: 0;
  }
}
.bubble:nth-child(1) {
  left: 3px;
  width: 15px;
  height: 15px;
  animation-delay: 0.1s;
  animation: flutter 14s infinite;
}
.bubble:nth-child(2) {
  left: 80px;
  width: 20px;
  height: 20px;
  animation-delay: 5s;
  animation: flutter2 13s infinite;
}
.bubble:nth-child(3) {
  left: 40px;
  width: 25px;
  height: 25px;
  animation: flutter 16s infinite;
}
.bubble:nth-child(4) {
  left: 50px;
  width: 12px;
  height: 12px;
  animation-delay: 5s;
  animation: flutter 13s infinite;
}
.bubble:nth-child(5) {
  left: 70px;
  width: 30px;
  height: 30px;
  animation-delay: 7s;
  animation: flutter2 15s infinite;
}
.bubble:nth-child(6) {
  left: 70px;
  width: 16px;
  height: 16px;
  animation-delay: 7s;
  animation: flutter2 14s infinite;
}
</style>
