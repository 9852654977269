/*
 * @Date: 2022-06-20 11:08:54
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-03-30 19:48:58
 * @Description:
 */
import Vue from 'vue';
import App from './App.vue';
import '@/common/main';
import router from '@/router/index.js';
import 'animate.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
Vue.use(VueAwesomeSwiper);

// import 'lib-flexible'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
