<!--
 * @Date: 2022-06-28 09:43:15
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-05-06 17:05:14
 * @Description: layout 页面
-->
<template>
  <div id="page">
    <headerView></headerView>
    <div class="content">
      <bannerView></bannerView>
      <div class="own-bg" :style="{ height: 'calc(100% - ' + b_h + 'px)' }"></div>
      <div class="bg1">
        <strategyView></strategyView>
        <specialView></specialView>
      </div>
      <superiorityView></superiorityView>
      <partnerView></partnerView>
    </div>
    <footerView></footerView>
    <backTopView></backTopView>
  </div>
</template>

<script>
import headerView from '@/components/header.vue';
import footerView from '@/components/footer.vue';
import backTopView from '@/components/backTop.vue';
import bannerView from './banner.vue';
import strategyView from './strategy.vue';
import specialView from './special.vue';
import superiorityView from './superiority.vue';
import partnerView from './partner.vue';
export default {
  components: {
    footerView,
    headerView,
    backTopView,
    bannerView,
    strategyView,
    specialView,
    superiorityView,
    partnerView
  },
  data() {
    return {
      b_h: 0
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.b_h = document.querySelector('.banner').clientHeight + 504;
    });
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.bg1 {
  overflow: hidden;
  position: relative;
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: -1;
  //   opacity: 0.4;
  //   background: #f5f7f9;
  //   filter: blur(2px);
  // }
}
</style>
<style lang="less">
// 通用样式
.step-title {
  font-family: PingFangSC-SNaNpxibold;
  font-weight: 600;
  font-size: 40px;
  color: #04152f;
  text-align: center;
  line-height: 60px;
  margin-top: 70px;
}
.step-txt {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #4e5b6d;
  line-height: 28px;
}
html {
  #page .content::before {
    display: none;
  }
}
.own-bg {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 3842px;
  // height: calc(100%);
  background-image: url(./assets/bg.png);
  background-size: 100% auto;
  z-index: -99;
}
</style>
