<!--
 * @Date: 2023-03-18 13:07:57
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-05-18 11:16:25
 * @Description: 
-->
<template>
  <div class="strategy">
    <div class="step-title">公司战略</div>
    <div class="step-txt">
      汇科智创基于城市建设、治理综合数智化服务的核心理念，服务于政府类、平台类、企业类客户，支撑项目规划设计、建设实施、运营维护阶段，提供咨询服务、定制开发、产品服务、集成服务、硬件研发五大业务种类，在数字建造、数字城管、数字房管、数字水务、数字企管、数字基运、数字园区、数字园林八大专业领域拥有丰富的行业经验。
    </div>
    <div class="step-box">
      <template v-for="el in stepBox[0]">
        <div
          class="step-cell"
          :class="{ active: showIndex === el.index }"
          @mouseenter="change(el)"
          :key="el.index"
          @mouseleave="leave(el)"
          @click="to(el.url)"
        >
          <div class="top">
            <div class="num">0{{ el.index }}</div>
            <div class="name">
              {{ el.title }}
              <img
                v-if="showIndex === el.index"
                src="@/assets/imgs/home_icon_arrow.svg"
                alt=""
                class="arrow animate__animated animate__fadeInLeft"
              />
            </div>
            <component :is="el.bg" :class="el.bg" :ref="'iconRef' + el.index"></component>
            <div class="bg1"></div>
            <div class="bg2"></div>
            <div class="filter"></div>
          </div>
        </div>
      </template>
      <div class="bottom-box">
        <div
          class="bottom animate__animated animate__slideInDown"
          v-if="[1, 2, 3].includes(showIndex)"
          @click="to(showItem.url)"
        >
          <div v-for="info of showItem.info" class="bottom-cell">{{ info.title }}</div>
        </div>
      </div>
    </div>
    <div class="step-box">
      <template v-for="el in stepBox[1]">
        <div
          class="step-cell"
          :class="{ active: showIndex === el.index }"
          @mouseenter="change(el)"
          :key="el.index"
          @mouseleave="leave(el)"
          @click="to(el.url)"
        >
          <div class="top">
            <div class="num">0{{ el.index }}</div>
            <div class="name">
              {{ el.title }}
              <img
                v-if="showIndex === el.index"
                src="@/assets/imgs/home_icon_arrow.svg"
                alt=""
                class="arrow animate__animated animate__fadeInLeft"
              />
            </div>
            <component :is="el.bg" :class="el.bg" :ref="'iconRef' + el.index"></component>
            <div class="bg1"></div>
            <div class="bg2"></div>
            <div class="filter"></div>
          </div>
        </div>
      </template>
      <div class="bottom-box">
        <div
          class="bottom animate__animated animate__slideInDown"
          v-if="[4, 5].includes(showIndex)"
          @click="to(showItem.url)"
        >
          <div v-for="info of showItem.info" class="bottom-cell">{{ info.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from './assets/strategy/01/index.vue';
import icon2 from './assets/strategy/02/index.vue';
import icon3 from './assets/strategy/03/index.vue';
import icon4 from './assets/strategy/04/index.vue';
import icon5 from './assets/strategy/05/index.vue';
import { ZXFW, DZKF, CPFW, JCFW, YJKF } from '@/data/strategy';
export default {
  components: {
    icon1,
    icon2,
    icon3,
    icon4,
    icon5
  },
  data() {
    return {
      showIndex: 1,
      timer: null,
      stepBox: [
        [
          {
            title: '咨询服务',
            bg: 'icon1',
            index: 1,
            info: ZXFW,
            url: '/business.html#zxfw'
          },
          {
            title: '定制开发',
            bg: 'icon2',
            index: 2,
            info: DZKF,
            url: '/business.html#dzkf'
          },
          {
            title: '产品服务',
            bg: 'icon3',
            index: 3,
            info: CPFW,
            url: '/business.html#cpfw'
          }
        ],
        [
          {
            title: '集成服务',
            bg: 'icon4',
            index: 4,
            info: JCFW,
            url: '/business.html#jcfw'
          },
          {
            title: '硬件研发',
            bg: 'icon5',
            index: 5,
            info: YJKF,
            url: '/business.html#yjkf'
          }
        ]
      ]
    };
  },
  created() {},
  computed: {
    showItem() {
      return [].concat(this.stepBox[0], this.stepBox[1]).find(el => el.index === this.showIndex);
    }
  },
  methods: {
    change(el) {
      this.showIndex = el.index;
      this.$refs['iconRef' + el.index][0].startTimer();
    },
    leave(el) {
      this.$refs['iconRef' + el.index][0].endTimer();
    },
    to(url) {
      window.location.href = url;
    }
  }
};
</script>

<style lang="less" scoped>
.strategy {
  width: 1239px;
  margin: 0 auto 120px;
  .step-title {
    margin-bottom: 26px;
  }
  .step-txt {
    width: 1000px;
    margin: auto;
    margin-bottom: 88px;
    text-align: center;
  }
  .step-box {
    .step-cell {
      float: left;
      width: 33.33333333%;
      position: relative;
      z-index: 2;
      .top {
        height: 282px;
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        .bg1,
        .bg2 {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .bg1 {
          background: #ebeff4;
          background-image: url(./assets/strategy/bg.svg);
          background-size: 100% 100%;
          z-index: -3;
        }
        .bg2 {
          z-index: -2;
        }
        .filter {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 317px;
          height: 195px;
          transition: unset;
          z-index: -4;
        }
        * {
          transition: all linear 0.3s;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
        }
        .num {
          font-size: 160px;
          font-family: FRIZON;
          color: rgba(2, 98, 233, 0);
          line-height: 170px;
          -webkit-text-stroke: 1px #006aff;
          text-stroke: 1px #006aff;
          opacity: 0.15;
          backdrop-filter: blur(4px);
        }
        .name {
          font-family: PingFangSC-SNaNpxibold;
          font-weight: 600;
          font-size: 32px;
          line-height: 42px;
          color: #04152f;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 41px;
        }
      }
      &:hover,
      &.active {
        z-index: 3;
        .top {
          .bg1 {
            background: #ebeff4;
          }
          .bg2 {
            background: #f5f7f9;
          }

          .filter {
            // background: #006aff;
            // opacity: 0.21;
            // filter: blur(121px);
            // z-index: -1;
            box-shadow: 3px 13px 60px -5px rgba(0, 106, 255, 0.21);
          }
          .num {
            opacity: 0.15;
            color: #0262e9;
            -webkit-text-stroke: 1px #006aff;
          }
          .name {
            color: #0262e9;
          }
          &::before {
            opacity: 0.15;
            border: 2px solid;
            border-image: linear-gradient(180deg, rgba(0, 106, 255, 0), rgba(0, 106, 255, 1)) 2 2;
          }
        }
      }
    }
    & + .step-box {
      margin-top: 41px;
    }
  }
  .bottom-box {
    width: 100%;
    clear: both;
    overflow: hidden;
  }
  .bottom {
    float: left;
    width: 100%;
    padding: 35px 0 0;
    background: #f5f7f9;
    overflow: hidden;
    .bottom-cell {
      float: left;
      width: 33.33333333%;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #04152f;
      line-height: 30px;
      cursor: pointer;
      padding-left: 40px;
      margin-bottom: 35px;
      cursor: pointer;
    }
  }
  .icon1 {
    position: absolute;
    bottom: 121px;
    right: -22px;
  }
  .icon2 {
    position: absolute;
    bottom: 127px;
    right: -16px;
  }
  .icon3 {
    position: absolute;
    bottom: 127px;
    right: -16px;
  }
  .icon4 {
    position: absolute;
    bottom: 127px;
    right: -16px;
  }
  .icon5 {
    position: absolute;
    bottom: 129px;
    right: -25px;
    transform: scale(1.2);
  }
}
</style>
