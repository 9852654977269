<!--
 * @Date: 2023-03-21 18:07:42
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-05-06 16:06:31
 * @Description: 
-->
<template>
  <div class="png" ref="view">
    <img src="./dot1.png" alt="" class="dot1" />
    <img src="./dot2.png" alt="" class="dot2" />
    <img src="./dot3.png" alt="" class="dot3" />
    <div class="arrow1-box">
      <img src="./arrow1.png" alt="" class="arrow1" :class="{ animate: arrowShow }" />
    </div>
    <div class="arrow2-box">
      <img src="./arrow2.png" alt="" class="arrow2" :class="{ animate: arrowShow }" />
    </div>
  </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';
export default {
  components: {},
  data() {
    return {
      timer: null,
      arrowShow: false
    };
  },
  created() {},
  mounted() {
    useIntersectionObserver(this.$refs.view, ([{ isIntersecting }], observerElement) => {
      // targetIsVisible.value = isIntersecting
      if (isIntersecting) this.arrowShow = true;
    });
  }
};
</script>

<style lang="less" scoped>
.png {
  width: 320px;
  height: 370px;
  background-image: url(./bg.png);
  background-size: 100% 100%;
  .dot1 {
    width: 36px;
    height: auto;
    position: absolute;
    top: 22px;
    left: 44px;
    animation: dot1 4s linear infinite;
    transform: translate3d(0, 0, 0) scale(1);
    filter: brightness(1);
  }
  .dot2 {
    width: 24px;
    height: auto;
    position: absolute;
    top: 150px;
    right: 34px;
    animation: dot1 4s linear infinite;
    transform: translate3d(0, 0, 0) scale(1);
    filter: brightness(1);
    z-index: -1;
  }
  .dot3 {
    width: 36px;
    height: auto;
    position: absolute;
    animation: dot1 4s linear infinite;
    transform: translate3d(0, 0, 0) scale(1);
    filter: brightness(1);
    top: 270px;
    left: 110px;
  }
  .arrow1-box {
    width: 70px;
    height: 47px;
    position: absolute;
    top: 140px;
    left: 130px;
    transform-style: preserve-3d;
    perspective: 70px;
    overflow: hidden;

    .arrow1 {
      width: 70px;
      position: absolute;
      top: 0;
      left: 0;
      &.animate {
        animation: arrow1 1s linear;
      }
    }
  }
  .arrow2-box {
    width: 70px;
    height: 47px;
    position: absolute;
    top: 210px;
    left: 130px;
    transform-style: preserve-3d;
    perspective: 70px;
    overflow: hidden;

    .arrow2 {
      width: 70px;
      position: absolute;
      top: 0;
      left: 0;
      &.animate {
        animation: arrow2 1s linear;
      }
    }
  }
  @keyframes dot1 {
    0% {
      transform: translate3d(0, 0, 0) scale(1);
      filter: brightness(1);
    }
    20% {
      transform: translate3d(12px, -12px, 0) scale(0.9);
      filter: brightness(1.2);
    }
    60% {
      transform: translate3d(8px, 12px, 0) scale(1.1);
      filter: brightness(1.6);
    }
    80% {
      transform: translate3d(-7px, 8px, 0) scale(0.8);
      filter: brightness(1.3);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      filter: brightness(1);
    }
  }
  @keyframes arrow1 {
    0% {
      transform: translate3d(-100%, -100%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes arrow2 {
    0% {
      transform: translate3d(100%, -100%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
}
</style>
