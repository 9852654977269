<template>
  <div
    class="png"
    ref="view"
    :style="{ 'background-position-y': ((-1 * ((14430 / 320) * 310)) / 39) * step + 'px' }"
  ></div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';
export default {
  data() {
    return {
      step: 0,
      time: 1000,
      maxStep: 38,
      timer: null
    };
  },
  mounted() {
    useIntersectionObserver(this.$refs.view, ([{ isIntersecting }], observerElement) => {
      if (isIntersecting && !this.step) this.startTimer();
    });
  },
  methods: {
    startTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.step < this.maxStep) {
          this.step++;
          this.startTimer();
        }
      }, this.time / this.maxStep);
    },
    endTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.step > 0) {
          this.step--;
          this.endTimer();
        }
      }, this.time / this.maxStep);
    }
  }
};
</script>

<style lang="less" scoped>
.png {
  width: 310px;
  height: 320px;
  background: url(./bg.png) no-repeat 0px 0px;
  background-size: 100% auto;
}
</style>
