var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"view",staticClass:"png"},[_c('div',{staticClass:"numbox"},[_c('div',{staticClass:"num"},[_c('div',{staticClass:"num-inner",style:({
          transform: _vm.numShow ? 'translateY(calc(-100% + 75px))' : '',
          'transition-duration': 10 / 8 + 's'
        })},_vm._l((8),function(i){return _c('div',[_vm._v(_vm._s(i - 1))])}),0),_c('div',{staticClass:"num-inner",style:({
          transform: _vm.numShow ? 'translateY(calc(-100% + 75px))' : '',
          'transition-duration': 10 / 6 + 's'
        })},_vm._l((6),function(i){return _c('div',[_vm._v(_vm._s(i - 1))])}),0)]),_c('div',{staticClass:"percent"},[_vm._v("%")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }