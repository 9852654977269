<!--
 * @Date: 2023-03-21 20:41:51
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-19 14:42:00
 * @Description: 
-->
<template>
  <div class="parhner">
    <div class="step-title">合作伙伴</div>
    <div v-for="(item, index) in imgs" class="swiper-box" :key="index">
      <div
        class="swiper-inner-box"
        :style="{
          'animation-duration': item.length * 1.93 * 6 + 's'
        }"
        :class="index % 2 ? 'right' : 'left'"
      >
        <div class="swiper-inner" v-for="url of item" :key="url + '1'">
          <img :src="url" alt="" />
        </div>
        <div class="swiper-inner" v-for="url of item" :key="url + '2'">
          <img :src="url" alt="" />
        </div>
        <div class="swiper-inner" v-for="url of item" :key="url + '3'">
          <img :src="url" alt="" />
        </div>
        <div class="swiper-inner" v-for="url of item" :key="url + '4'">
          <img :src="url" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    let that = this;
    return {
      imgs: [],
      swiperOptions: {
        observer: true,
        observeSlideChildren: true,
        observeParents: true, //这三个可以去文档看下介绍，主要就是异步情况会重新加载swiper。
        loop: true, //开启环路
        speed: 7000, //每个轮播图过渡的时间
        spaceBetween: 24, //每个轮播图间隔
        slidesPerView: 'auto', //当前页面显示几个，这里是auto
        loopedSlides: 2, //比你页面显示的数量大于2就好
        updateOnWindowResize: false, //分辨率改变时，防止样式错乱加上！
        autoplay: {
          delay: 0, //每循环一圈，间隔时间，无缝轮询，则为0
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      }
    };
  },
  created() {
    this.$service({
      url: '/enterprise/partner/home_logo_list',
      method: 'post',
      data: {
        pageNum: 1,
        pageSize: 1000
      }
    }).then(res => {
      let imgName = res.data.map(el => el.attUrl);
      let decaly = 36 - imgName.length;
      // if (decaly > 0) imgName = imgName.concat(imgName.slice(0, decaly));
      let n = parseInt(imgName.length / 3);
      let imgs = [[], [], []];
      imgName.forEach((url, i) => {
        if (i < n) {
          imgs[0].push(url);
        } else if (i < 2 * n) {
          imgs[1].push(url);
        } else {
          imgs[2].push(url);
        }
      });
      this.imgs = imgs;
    });
  }
};
</script>

<style lang="less" scoped>
.parhner {
  padding-bottom: 80px;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f7f9;
    opacity: 0.4;
    filter: blur(2px);
    z-index: -1;
  }
  .step-title {
    margin-bottom: 70px;
  }
  .swiper-box {
    width: 100%;
    height: 80px;
    position: relative;
    + .swiper-box {
      margin-top: 40px;
    }
    .swiper-inner-box {
      position: absolute;
      white-space: nowrap;
      left: 0;
      top: 0;
      height: 100%;
      &.left {
        animation: animateleft 0s infinite linear;
      }
      &.right {
        transform: translateX(50%);
        animation: animateright 0s infinite linear;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
    .swiper-inner {
      width: 193px;
      height: 80px;
      margin-right: 24px;
      background: #ffffff;
      box-shadow: 0 2px 10px 0 #0262e91a;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
@keyframes animateleft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes animateright {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>
