<!--
 * @Date: 2023-03-18 14:07:08
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-07-18 18:20:39
 * @Description: 
-->
<template>
  <div class="special">
    <!-- <div class="special" :class="windowW > 1920 ? 'outerSide' : ''"> -->
    <div class="img-container" id="carousel">
      <swiper ref="mySwiper" :options="swiperOptions" style="height: 100%">
        <swiper-slide v-for="(item, index) of list" :key="index">
          <div class="swiper-inner">
            <img :src="item.img" alt="" />
            <div class="info">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="info-b">
                <div class="txt">
                  {{ item.txt }}
                </div>
                <div class="showInfo" :data-index="index">查看详情</div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <template slot="button-prev">
          <div class="swiper-button-prev"></div>
        </template>
        <template slot="button-next">
          <div class="swiper-button-next"></div>
        </template>
      </swiper>
    </div>
    <div class="bg-box">
      <div class="special-title">
        <span class="num">8/</span>
        <span>专业领域</span>
      </div>
      <img src="./assets/special/bg.png" alt="" />
      <div class="index">{{ nowIndex + 1 }}/8</div>
    </div>
  </div>
</template>

<script>
let slideW = 300; //一张图300px, 每面四张角度22.5（PI/8），中心角度PI/16
let radius = (slideW * 0.5) / Math.sin(Math.PI / 16); //半径。圆心并不是视点中心，视点
export default {
  components: {},
  data() {
    let that = this;
    return {
      windowW: window.innerWidth,
      list: [
        {
          img: 'http://ow-web.hysz.co/plan/szjz/数字工地监管大数据平台.png',
          title: '数字建造',
          txt: '基于BIM应用，利用数字工具改善工程产品的整个交付和运维服务流程，使建筑环境的交付、运营和更新更加协调、安全、高效。为工程建设行业提供设计施工运维一体化、软硬集成一体化的BIM及数字化整体解决方案，加速行业BIM应用推广，赋能项目智能建造。利用云计算、大数据、人工智能等现代信息技术促进工程价值链提升和产业变革，提供以人为本、绿色可持续的智能化工程产品与服务以及“平台+工具+方案”的多维整体解决方案。',
          url: '/plan.html?type=SZJZ'
        },
        {
          img: 'http://ow-web.hysz.co/plan/szcg/数字城管-bg.png',
          title: '数字城管',
          txt: '数字城管以物联网、人工智能、移动互联网等新一代信息技术为支撑，通过全面透彻感知、宽带泛在互联、智能融合应用，提供多尺度的城市态势感知和多模事件精准识别处理服务，及时识别各类城市管理问题，构建沟通快捷、责任到位、处置及时、运转高效的城市管理、公共服务的监督和处置新机制，实现“绣花针式”城市管理。',
          url: '/plan.html?type=SZCG'
        },
        {
          img: 'http://ow-web.hysz.co/plan/szfg/房管.png',
          title: '数字房管',
          txt: '利用5G、移动地理信息、大数据、人工智能、物联网等技术，结合房屋普查数据、基础设施资源等数据资源，围绕房管局、巡检公司、物业公司各级监督管理的需求，构建出一个标准统一、现实性强、质量高的房管数据资源体系，形成一套集监督管理、信息综合、决策分析于一体的综合管理系统。',
          url: '/plan.html?type=SZFG'
        },
        {
          img: 'http://ow-web.hysz.co/plan/szsw/水务.png',
          title: '数字水务',
          txt: '通过建立监测体系，利用传感器等在线监测设备，实时感知城市供排水系统的运行状态，采用可视化的信息系统有机整合水务管理部门与供排水设施，形成城市水务物联网，可将海量水务信息进行及时分析与处理，做出相应的处理结果，辅助决策建议，以更加精细和动态的方式管理水务系统的整个生产、管理和服务流程。',
          url: '/plan.html?type=SZSW'
        },
        {
          img: 'http://ow-web.hysz.co/plan/szyl/龙阳湖.png',
          title: '数字园林',
          txt: '融合新兴科技与园林资产管理和保护，建立园林“一张图”，实现园林资源、巡查人员、监测设备、养护物资等园林资产的全方位监管，优化园林管理、运营流程。以数字化为核心，整合省市园林绿地信息，依托GIS 地理信息模型打造园林管理软硬一体化集成可视化平台，提供园林管理信息查询、数据统计、数据输出、空间分析、数据编辑、园林规划建设等功能。推进数字园林建设，实现园林管理智慧化、精细化、可视化。',
          url: '/plan.html?type=SZYL'
        },
        {
          img: 'http://ow-web.hysz.co/plan/szqy/主数据.png',
          title: '数字企管',
          txt: '围绕建筑企业数字化转型需求，以企业内部高效协同办公、工程项目全周期智慧化管控为目标，打造企业IT品牌矩阵，提升科学战略规划、智慧项目管理、综合数据运营等核心数字化能力，通过数字化转型实现弯道超车、产业转型升级，提高企业核心竞争力。',
          url: '/plan.html?type=SZQY'
        },
        {
          img: 'http://ow-web.hysz.co/plan/szjy/市政.png',
          title: '数字基运',
          txt: '以服务城市市政基础设施管理为宗旨，整合各类设施空间信息和属性信息，直观呈现各类设施空间分布，动态监测、模拟仿真、及时预警设施运行状态，准确评价设施服务水平，提升政府监管、权属单位运维保障能力，支撑一网统管及新型智慧城市建设。',
          url: '/plan.html?type=SZJY'
        },
        {
          img: 'http://ow-web.hysz.co/plan/szyq/智慧楼宇.png',
          title: '数字园区',
          txt: '拥有迅捷信息采集、高速信息传输、高度集中计算和智能事务处理能力。以数字化为核心，运用BIM技术、无人机航拍、三维扫描相结合的方式，提供园区实景、楼宇建筑、建筑内部空间结构和主要管理设施设备的三维虚拟空间场景，建立园区数字孪生仿真空间模型。实现安防、物业、能耗、日常管理的智慧化、统筹运营的可视化，为产业园区生产和生活协同提供智慧化体验，构建园区运营信息化、数字智能化、服务平台化、园区移动化的发展新格局。',
          url: '/plan.html?type=SZYQ'
        }
      ].map(el => ({
        ...el,
        enter: false
      })),
      nowIndex: 0,
      swiperOptions: {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: false,
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        grabCursor: true,
        on: {
          slideChangeTransitionEnd: function () {
            that.nowIndex = this.realIndex;
          },
          setTransition: function (swiper, transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
          click(e) {
            if (e.target.dataset.index !== undefined) {
              that.to(that.list[e.target.dataset.index].url);
            }
          }
        }
      }
    };
  },
  created() {},
  computed: {},
  methods: {
    to(url) {
      window.location.href = url;
    }
  }
};
</script>

<style lang="less" scoped>
.special {
  // max-width: 1920px;
  margin: auto;
  margin-bottom: 150px;
  position: relative;
  overflow: hidden;
  &.outerSide {
    &::before {
      content: '';
      position: absolute;
      top: 192px;
      left: 0;
      height: 399px;
      width: 155px;
      background-image: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.9;
      z-index: 3;
      pointer-events: none;
    }
    &::after {
      content: '';
      position: absolute;
      top: 192px;
      right: 0;
      height: 399px;
      width: 155px;
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      opacity: 0.9;
      z-index: 3;
      pointer-events: none;
    }
  }
  .img-container {
    position: absolute;
    bottom: 108px;
    left: 0;
    width: 100%;
    height: 458px;
    overflow: visible;
    padding-left: calc(50% - 400px - 25px);
    :deep(.swiper-container) {
      overflow: visible;
      .swiper-slide {
        width: 800px;
        transition: all linear 0.3s;
        .swiper-inner {
          width: 100%;
          height: 100%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          position: relative;
          background-image: url(./assets/home_specialty_line.png);
          background-size: 100% 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            border: 1px solid #006aff;
          }
          .info {
            position: absolute;
            width: calc(100% - 22px);
            height: calc(100% - 22px);
            border-radius: 8px;
            top: 11px;
            left: 11px;
            background: rgba(4, 21, 47, 0.6);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .title {
              font-family: PingFangSC-SNaNpxibold;
              font-weight: 600;
              font-size: 32px;
              color: #ffffff;
              text-align: center;
              line-height: 42px;
              text-shadow: 0 4px 2px rgba(0, 0, 0, 0.5);
            }
            .info-b {
              display: flex;
              flex-direction: column;
              align-items: center;
              // height: 233px;
              height: 0;
              opacity: 0;
              overflow: hidden;
              transition: opacity linear 0.5s;
              .txt {
                width: 540px;
                font-size: 18px;
                color: #ffffff;
                line-height: 28px;
                margin-top: 27px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
              }
              .showInfo {
                width: 180px;
                height: 48px;
                background: #006aff;
                border-radius: 4px;
                font-family: PingFangSC-SNaNpxibold;
                font-weight: 600;
                font-size: 18px;
                color: #ffffff;
                text-align: center;
                line-height: 48px;
                margin-top: 74px;
              }
            }
          }
          &:hover {
            .info {
              .info-b {
                height: 233px;
                opacity: 1;
              }
            }
          }
        }
        + .swiper-slide {
          margin-left: 25px;
        }
        &.swiper-slide-active {
          transform: translateY(-50px);
          .info {
            .info-b {
              height: 233px;
              opacity: 1;
            }
          }
        }
      }
    }
    .swiper-button-prev {
      position: absolute;
      left: unset;
      top: unset;
      right: calc(100% - 903px);
      bottom: -68px;
      width: 48px;
      height: 48px;
      background-image: url(./assets/special/left.webp);
      background-size: 100% 100%;
    }
    .swiper-button-next {
      position: absolute;
      left: unset;
      top: unset;
      right: calc(100% - 971px);
      bottom: -68px;
      width: 48px;
      height: 48px;
      background-image: url(./assets/special/right.webp);
      background-size: 100% 100%;
    }
  }
  .bg-box {
    width: 1239px;
    margin: auto;
    text-align: right;
    position: relative;
    .special-title {
      position: absolute;
      top: 34px;
      right: 39px;
      color: #ffffffff;
      font-size: 40px;
      font-family: 'PingFangSC-Medium';
      font-weight: 600;
      line-height: 60px;
      letter-spacing: 0;
      text-align: center;
      .num {
        font-weight: 400;
        font-family: 'FRIZON';
      }
    }
  }
  .index {
    position: absolute;
    right: 6px;
    bottom: 40px;
    color: #ffffffff;
    font-size: 30px;
    font-family: 'PingFangSC-Medium';
    font-weight: 600;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
  }
}
</style>
