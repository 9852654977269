<!--
 * @Date: 2023-03-20 15:52:13
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-03-21 08:34:09
 * @Description: 
-->
<template>
  <div
    class="png"
    :style="{ 'background-position-y': ((-1 * ((13000 / 500) * 210)) / 26) * step + 'px' }"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      step: 0,
      time: 200,
      maxStep: 13,
      timer: null
    };
  },
  methods: {
    startTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.step < this.maxStep) {
          this.step++;
          this.startTimer();
        }
      }, this.time / this.maxStep);
    },
    endTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.step > 0) {
          this.step--;
          this.endTimer();
        }
      }, this.time / this.maxStep);
    }
  }
};
</script>

<style lang="less" scoped>
.png {
  width: 210px;
  height: 210px;
  background: url(./bg.png) no-repeat 0px 0px;
  transition: unset !important;
  background-size: 100% auto;
  // -webkit-animation: huida20230314083533 1s infinite steps(26, end);
  // -moz-animation: huida20230314083533 1s infinite steps(26, end);
  // -ms-animation: huida20230314083533 1s infinite steps(26, end);
  // animation: huida20230314083533 1s steps(26, end);
}
@keyframes huida20230314083533 {
  0% {
    background-position-y: 0px;
  }
  100% {
    background-position-y: -13000 / 500 * 210px;
  }
}
</style>
