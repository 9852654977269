<!--
 * @Date: 2023-03-21 19:21:14
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-03-23 08:45:17
 * @Description: 
-->
<template>
  <div class="png" ref="view">
    <div class="numbox">
      <div class="num">
        <div
          class="num-inner"
          :style="{
            transform: numShow ? 'translateY(calc(-100% + 75px))' : '',
            'transition-duration': 10 / 8 + 's'
          }"
        >
          <div v-for="i in 8">{{ i - 1 }}</div>
        </div>
        <div
          class="num-inner"
          :style="{
            transform: numShow ? 'translateY(calc(-100% + 75px))' : '',
            'transition-duration': 10 / 6 + 's'
          }"
        >
          <div v-for="i in 6">{{ i - 1 }}</div>
        </div>
      </div>
      <div class="percent">%</div>
    </div>
  </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';
export default {
  components: {},
  data() {
    return {
      timer: null,
      numShow: false
    };
  },
  created() {},
  mounted() {
    useIntersectionObserver(this.$refs.view, ([{ isIntersecting }], observerElement) => {
      // targetIsVisible.value = isIntersecting
      if (isIntersecting) this.numShow = true;
    });
  }
};
</script>

<style lang="less" scoped>
.png {
  width: 320px;
  height: 370px;
  background-image: url(./bg.png);
  background-size: 100% 100%;
  position: relative;
  .numbox {
    font-family: FRIZON;
    color: #04152f;
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 138px;
    right: 0;
    justify-content: flex-end;
  }
  .num {
    font-size: 73px;
    line-height: 75px;
    height: 75px;
    overflow: hidden;
    .num-inner {
      float: left;
      transition: all linear 1s;
      &:first-child {
        text-align: right;
      }
    }
  }
  .percent {
    font-size: 50px;
    font-weight: 600;
  }
}
</style>
