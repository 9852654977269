<!--
 * @Date: 2023-03-21 20:35:09
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-03-22 15:09:35
 * @Description: 
-->
<template>
  <div class="png" ref="view">
    <div class="bg2" :style="{ transform: bgShow ? 'translateX(0)' : '' }"></div>
    <img src="./dot1.png" alt="" class="dot1" />
    <img src="./dot2.png" alt="" class="dot2" />
  </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';
export default {
  components: {},
  data() {
    return {
      timer: null,
      bgShow: false
    };
  },
  created() {},
  mounted() {
    useIntersectionObserver(this.$refs.view, ([{ isIntersecting }], observerElement) => {
      // targetIsVisible.value = isIntersecting
      if (isIntersecting) this.bgShow = true;
    });
  }
};
</script>

<style lang="less" scoped>
.png {
  width: 320px;
  height: 370px;
  background: url(./bg.png) no-repeat 0px 0px;
  position: relative;
  .bg2 {
    width: 320px;
    height: 370px;
    background: url(./bg2.png) no-repeat 0px 0px;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center;
    transform: translateX(48px);
    transition: all linear 1s;
  }
  .dot1 {
    width: 48px;
    height: auto;
    position: absolute;
    top: 76px;
    left: 18px;
    animation: dot1 4s linear infinite;
    transform: translate3d(0, 0, 0) scale(1);
    filter: brightness(1);
  }
  .dot2 {
    width: 32px;
    height: auto;
    position: absolute;
    bottom: 80px;
    right: 100px;
    animation: dot1 4s linear infinite;
    transform: translate3d(0, 0, 0) scale(1);
    filter: brightness(1);
    z-index: -1;
  }
  @keyframes dot1 {
    0% {
      transform: translate3d(0, 0, 0) scale(1);
      filter: brightness(1);
    }
    20% {
      transform: translate3d(12px, -12px, 0) scale(1);
      filter: brightness(1.2);
    }
    60% {
      transform: translate3d(8px, 12px, 0) scale(1);
      filter: brightness(1.6);
    }
    80% {
      transform: translate3d(-7px, 8px, 0) scale(1);
      filter: brightness(1.3);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      filter: brightness(1);
    }
  }
}
</style>
