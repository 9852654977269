<template>
  <div class="banner">
    <swiper ref="mySwiper" :options="swiperOptions" style="height: 100%" :key="list.length">
      <swiper-slide v-for="(item, index) of list" :key="index">
        <div class="swiper-inner">
          <img :src="item.img" alt="" />
          <template v-if="index === 0">
            <div class="info">
              <div
                class="info-inner"
                :style="{
                  transform: 'scale(' + (w / 1920 > 2 ? 2 : w / 1920 < 1 ? 1 : w / 1920 < 1) + ')'
                }"
              >
                <img src="./assets/banner_text.png" alt="" />
                <div class="aboutUs button-bg" :data-index="index">了解我们</div>
              </div>
            </div>
          </template>
        </div>
      </swiper-slide>
      <processPaginationView
        slot="pagination"
        class="my-swiper-pagination"
        :width="160"
        :max-len="list.length"
        :delay="swiperOptions.autoplay.delay"
        :index="nowIndex"
      ></processPaginationView>
    </swiper>
    <div class="swiper-index">
      {{ showIndex() }}
      <div class="bar">
        <div class="bar-cell"></div>
        <div class="bar-cell"></div>
        <div class="bar-cell"></div>
      </div>
    </div>
  </div>
</template>

<script>
import processPaginationView from '@/components/processPagination.vue';
export default {
  components: {
    processPaginationView
  },
  data() {
    let that = this;
    return {
      list: [{}, {}, {}, {}],
      nowIndex: 0,
      w: window.innerWidth,
      swiperOptions: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.my-swiper-pagination',
          type: 'custom'
        },
        on: {
          slideChangeTransitionEnd: function () {
            that.nowIndex = this.realIndex;
          },
          click(e) {
            if (e.target.dataset.index == 0) {
              that.to();
            }
          }
        }
      }
    };
  },
  created() {
    this.$service({
      url: '/reception/banner/page_list',
      method: 'post',
      data: {
        pageNum: 1,
        pageSize: 10
      }
    }).then(res => {
      this.list = res.data.dataList.map(el => ({
        img: el.attUrl
      }));
    });
  },
  computed: {},
  methods: {
    to() {
      window.location.href = '/about.html';
    },
    showIndex() {
      let i = this.nowIndex + 1;
      return i > 10 ? i : '0' + i;
    }
  }
};
</script>

<style lang="less" scoped>
.banner {
  height: 761 * 100 / 1920vw;
  min-height: 761px;
  max-height: 761 * 2px;
  overflow: hidden;
  position: relative;
  .swiper-inner {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-index {
    position: absolute;
    right: 418 - 65px;
    bottom: 83px;
    z-index: 1;
    width: 54px;
    font-family: FRIZON;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 3px;
    .bar {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 14px;
      height: 8px;
      overflow: hidden;
      .bar-cell {
        width: 2px;
        height: 28px;
        float: left;
        margin-right: 2px;
        transform-origin: center;
        background: #fff;
        transform: translateY(-7px) rotatez(27deg);
      }
    }
  }
  :deep(.my-swiper-pagination) {
    position: absolute;
    top: unset;
    left: unset;
    right: 418px;
    bottom: 83px;
    z-index: 2;
  }
  .info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 340px;
    z-index: 2;
    .info-inner {
      width: 100%;
      height: 100%;
      // transform-origin: top left;
      transform-origin: center left;
      // img {
      //   width: 756 * 100 / 1920vw;
      // }
    }
    .title {
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 120px;
      color: #04152f;
      letter-spacing: 10.5px;
      line-height: 150px;
    }
    .subtitle {
      font-family: FRIZON;
      font-size: 32px;
      color: #006aff;
      letter-spacing: 4px;
      line-height: 48px;
    }
    .aboutUs {
      margin-top: 51px;
      width: 200px;
      height: 60px;
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      cursor: pointer;
    }
  }
}
</style>
