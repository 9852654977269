<template>
  <div class="superiority">
    <div class="step-title">企业优势</div>
    <div class="step-boxs">
      <div
        class="step-box"
        v-for="(item, index) in stepBox"
        :key="index"
        :class="['step-box' + index]"
        :ref="'stepBox' + index"
        @mouseenter="change(index)"
      >
        <div
          class="step-box-inner"
          v-if="item.show"
          :class="[item.show ? 'step-box-show animate__animated animate__fadeInUpBig' : '']"
        >
          <div class="step-box-title">{{ item.title }}</div>
          <div class="step-box-text">{{ item.text }}</div>
          <div class="step-box-btns">
            <div class="step-box-btn button-bg2" v-for="el in item.btns">
              <img :src="el.icon" alt="" />
              {{ el.text }}
            </div>
          </div>
          <component :is="item.icon" v-if="item.icon" class="icon"></component>
        </div>
        <div class="bg2"></div>
        <div class="filter"></div>
      </div>
    </div>
    <table></table>
  </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';
import icon1 from './assets/superiority/01/index.vue';
import icon2 from './assets/superiority/02/index.vue';
import icon3 from './assets/superiority/03/index.vue';
import icon4 from './assets/superiority/04/index.vue';
import icon5 from './assets/superiority/05/index.vue';
import icon6 from './assets/superiority/06/index.vue';
export default {
  components: {
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6
  },
  data() {
    return {
      showIndex: 0,
      timer: null,
      stepBox: [
        {
          show: false,
          title: '服务领域广',
          text: '涵盖从软件定制开发、智能集成到BIM咨询等多方面的定制化服务，从售前到售后，为客户提供一站式全流程服务。',
          btns: [
            {
              text: '定制开发',
              icon: require('./assets/superiority/home_superiority_icon_develop.svg'),
              url: ''
            },
            {
              text: '一站式服务',
              icon: require('./assets/superiority/home_superiority_icon_services.svg'),
              url: ''
            }
          ],
          icon: 'icon1'
        },
        {
          show: false,
          title: '团队实力强',
          text: '公司拥有大量来自住建、房管、城管、水务等各行各业信息技术服务经验的专业人才，且985/211本、硕、博学历在职员工占比75%以上，团队实力强劲。',
          btns: [
            {
              text: '专业人才',
              icon: require('./assets/superiority/home_superiority_icon_professionals.svg'),
              url: ''
            }
          ],
          icon: 'icon2'
        },
        {
          show: false,
          title: '企业资质全',
          text: '具有CMMI 3、ITSS 3、ISO、电子与智能化工程专业承包壹级等完善的信息化相关资质，专业承接项目总集、分包实施、运行维护等多类型信息化项目。',
          btns: [
            {
              text: '多类型项目',
              icon: require('./assets/superiority/home_superiority_icon_item.svg'),
              url: ''
            }
          ],
          icon: 'icon3'
        },
        {
          show: false,
          title: '资源供给足',
          text: '作为汉阳市政集团的全资子公司，在湖北省具备完整的生态圈资源，与众多智慧城市细分领域的本地企业已形成良好协同机制与合作关系，本地化技术服务资源丰富，为业主提供全天候、全方位支撑。',
          btns: [
            {
              text: '良好资源',
              icon: require('./assets/superiority/home_superiority_icon_resource.svg'),
              url: ''
            }
          ],
          icon: 'icon4'
        },
        {
          show: false,
          title: '业务理解深',
          text: '依托汉阳市政几十年工程项目背景，从企业数字化、行业信息化、城市智慧化一路走来，聚焦城市规设建管全流程场景，且已落地汉阳古城区实际案例，通过大量实践，对业务理解极其深入、透彻。',
          btns: [
            {
              text: '数智工地',
              icon: require('./assets/superiority/home_superiority_icon_city.svg'),
              url: ''
            }
          ],
          icon: 'icon5'
        },
        {
          show: false,
          title: '保障能力佳',
          text: '主导了区域智慧城市等大型专业项目建设，提供总体集成和核心技术支撑，深刻理解建设难点重点与应对之道，具有极高的项目交付保障能力。',
          btns: [
            {
              text: '成熟技术',
              icon: require('./assets/superiority/home_superiority_icon_develop.svg'),
              url: ''
            }
          ],
          icon: 'icon6'
        }
      ]
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.stepBox.forEach((_, i) => {
      useIntersectionObserver(
        this.$refs['stepBox' + i][0],
        ([{ isIntersecting }], observerElement) => {
          if (isIntersecting && !this.step) this.stepBox[i].show = true;
        }
      );
    });
  },
  methods: {
    change(index) {
      this.showIndex = index;
    }
  }
};
</script>
<style lang="less" scoped>
.superiority {
  width: 413 * 3px;
  margin: auto;
  .step-title {
    margin-bottom: 70px;
  }
  .step-boxs {
    overflow: hidden;
    margin-bottom: 70px;
  }
  .step-box {
    width: 413px;
    height: 661px;
    padding: 367px 60px 0;
    float: left;
    position: relative;
    .step-box-inner {
      position: relative;
      // display: none;
      &.step-box-show {
        // display: unset;
      }
      .step-box-text {
        max-width: 298px;
      }
    }
    .bg2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .bg2 {
      z-index: -2;
    }
    .filter {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 317px;
      height: 195px;
      transition: unset;
      z-index: -4;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
    }
    // &:hover {
    //   z-index: 3;
    //   .bg2 {
    //     background: #fafbfc;
    //   }

    //   .filter {
    //     // background: #006aff;
    //     // opacity: 0.21;
    //     // filter: blur(121px);
    //     // z-index: -1;
    //     box-shadow: 3px 13px 60px -5px rgba(0, 106, 255, 0.21);
    //   }
    //   &::before {
    //     opacity: 0.15;
    //     border: 2px solid;
    //     border-image: linear-gradient(270deg, #006aff, rgba(0, 106, 255, 0), #006aff) 4 4;
    //   }
    // }
    .icon {
      position: absolute;
      bottom: 100%;
      right: 0;
      z-index: -1;
    }
    &-title {
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 32px;
      color: #006aff;
      line-height: 42px;
      margin-bottom: 14px;
    }
    &-text {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 18px;
      color: #4e5b6d;
      line-height: 28px;
      margin-bottom: 18px;
    }
    &-btns {
      overflow: hidden;
    }
    &-btn {
      display: flex;
      float: left;
      line-height: 40px;
      width: 120px;
      justify-content: center;
      white-space: nowrap;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      align-items: center;
      img {
        margin-right: 6px;
      }
      & + .step-box-btn {
        margin-left: 8px;
      }
    }
  }
  .step-box0 {
    .icon {
      transform: translate(39px, 49px);
    }
  }
  .step-box1 {
    .icon {
      transform: translate(19px, 40px);
    }
  }
  .step-box2 {
    .icon {
      transform: translate(38px, 41px);
    }
  }
  .step-box3 {
    .icon {
      transform: translate(62px, 35px);
    }
  }
  .step-box4 {
    .icon {
      transform: translate(36px, 6px);
    }
  }
  .step-box5 {
    .icon {
      transform: translate(62px, 44px);
    }
  }
}
</style>

<style lang="less">
// #page {
//   .step-box0 {
//     .dot1,
//     .dot2,
//     .dot3 {
//       animation-play-state: paused;
//     }
//     &:hover {
//       .dot1,
//       .dot2,
//       .dot3 {
//         animation-play-state: running;
//       }
//     }
//   }
//   .step-box2 {
//     .png {
//       animation-play-state: paused;
//     }
//     &:hover {
//       .png {
//         animation-play-state: running;
//       }
//     }
//   }
//   .step-box3 {
//     .bubble {
//       animation-play-state: paused;
//     }
//     &:hover {
//       .bubble {
//         animation-play-state: running;
//       }
//     }
//   }
//   .step-box5 {
//     .dot1,
//     .dot2 {
//       animation-play-state: paused;
//     }
//     &:hover {
//       .dot1,
//       .dot2 {
//         animation-play-state: running;
//       }
//     }
//   }
// }
</style>
